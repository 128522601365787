.channel-infor-modal .ant-modal-content {
  background-color: #232222;
  color: #fff;
  font-family: "Montserrat-Regular";
}

.channel-infor-modal .ant-modal-body {
  max-height: calc(var(--app-height) - 200px);
  overflow: hidden;
}
/* 
.channel-infor-modal .channel-name:hover svg {
  transition: all 0.3s ease-in;
  opacity: 1;
  cursor: pointer;
} */

.container-edit-input-channel {
  width: 100%;
  font-size: 16px;
  background: none;
  outline: none;
  border: 2px solid #555252;
  box-shadow: none;
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
}

.container-edit-input-channel:focus-within {
  border: 2px solid #005fff;
  transition: all 0.2s ease;
}

.channel-infor-modal .edit-input-channel-name {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #fff;
  width: 90%;
}

/* .channel-infor-modal .channel-name svg {
  transition: all 0.2s ease-out;
  opacity: 0;
} */

.list-members {
  max-height: calc(var(--app-height) - 400px);
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 283px;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  .channel-infor-modal .ant-modal-content {
    padding: 12px;
  }
}
