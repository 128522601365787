header {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  font-family: "Montserrat-Regular";
  flex-wrap: wrap;
  padding-bottom: 32px;
  padding-top: 32px;
  gap: 8px;
}

.container-header {
  /* margin-left: 30px; */
}

header h2 {
  cursor: pointer;
  font-size: 35px;
  font-family: Montserrat-Bold;
  color: #e5e5ea;
}

.container-web3-button {
  display: flex;
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .container-header {
    margin-left: 0px;
  }

  header h2 {
    font-size: 24px;
  }
}
