.user-container {
  cursor: pointer;
}
.user-container:hover {
  background-color: #2e3033;
  border-radius: 8px;
}

.list-user {
  max-height: calc(var(--app-height) - 400px);
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 283px;
}

.user-container_online {
  color: cornflowerblue;
}

.user-container_selected {
  background-color: #444444;
  border-radius: 8px;
}

.list-user-modal .ant-modal-content {
  background-color: #232222;
  color: #fff;
  font-family: "Montserrat-Regular";
}

.list-user-modal .ant-modal-body {
}

.input-group-name {
  border-radius: 18px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #2e3033;
  border: 1px solid #2e3033;
  margin-top: 8px;
  color: #fff;
  padding: 0.5rem 1rem;
}

.input-group-name:focus {
  border: 2px solid #005fff;
}
