.btn-remove-user {
  cursor: pointer;
  background-color: #d63030;
  padding: 2px 12px;
  border-radius: 4px;
  box-shadow: none;
  border: none;
  color: #fff;
  font-weight: 600;
}
.btn-remove-user:active {
  opacity: 0.8;
}
