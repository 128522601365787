.str-chat__channel-list {
  padding: 10px 20px 20px;
}

.str-chat__channel-list-messenger__main {
  display: grid;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  max-height: calc(var(--app-height) - 250px);
}

/* Mobile View */
@media screen and (max-width: 640px) {
  .str-chat__channel-list-messenger__main {
    max-height: calc(var(--app-height) - 137px);
  }
}
