.login-page {
  height: 100%;
}

.text-container {
  color: #fff;
  font-family: "Montserrat-Regular";
}

.container-video {
  max-height: 500px;
  margin-top: 60px;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  .container-video {
    max-height: 400px;
    margin-top: 0px;
  }
}
