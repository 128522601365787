.messaging__sidebar {
  height: 100%;
  display: grid;
  grid-template-rows: min-content;
}

.messaging__channel-list__header {
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 28px;
}

.messaging__channel-list__header__name {
  display: flex;
  line-height: 20px;
  font-size: 16px;
  margin: 0 10px;
}

.messaging__channel-list__header__button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.str-chat__load-more-button {
  display: none;
}

.str-chat__load-more-button button {
  width: 100% !important;
}
