.avatar-group {
  display: flex;
  align-items: center;
  /* height: 40px; */
  /* min-width: 40px; */
  /* max-width: 40px; */
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}

.avatar-group .str-chat__avatar {
  font-size: unset !important;
}

.avatar-group .str-chat__avatar .str-chat__avatar-image {
  border-radius: 0;
}
